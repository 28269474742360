<template>
  <div>
    <!-- 接入头部组件 -->
    <myheader></myheader>
    <div class="container">
      <div style="width: 1200px; margin: 0 auto">
        <a-breadcrumb style="margin: 30px 0">
          <a-breadcrumb-item><a href="/news_information"> &lt; 返回</a></a-breadcrumb-item>
          <a-breadcrumb-item><a href="/news_information">新闻资讯</a></a-breadcrumb-item>
          <a-breadcrumb-item>{{ news.title }}</a-breadcrumb-item>
        </a-breadcrumb>
      </div>
      <div class="top" :bordered="false">
        <h1 :style="{ textAlign: 'center', fontSize: '30px', fontWeight: '30px' }">{{ news.title }}</h1>
        <div style="text-align: center">发布时间:{{ news.created }}</div>
      </div>
      <div class="content">
        <div class="content-text" v-html="news.content"></div>
      </div>
      <div class="new-bottom" :bordered="false">
        <div style="width: 800px; margin: 0 auto">
          <div v-if="accessoryIs">
            附件:
            <div v-for="(file, index) in news.accessories" :key="index">
              <a-button type="link" icon="download" :size="size" @click="download(file.path)"> {{ file.fileName }} </a-button>
            </div>
          </div>
          <div v-else hidden>无附件</div>
          <!-- <div v-if="news.link != null" :style="{ margin: '10px 0' }">
            链接地址 : <a :style="{ fontSize: '19px' }" :href="'http://' + news.link">{{ news.link }}</a>
          </div>
          <div :style="{ fontSize: '10px', display: 'flex', justifyContent: 'space-between' }">
            <div>创建日期:{{ news.created }}</div>
            <div>更新日期:{{ news.updated }}</div>
            <div>上架日期:{{ news.publishDate }}</div>
            <div>下架日期:{{ news.unPublishDate }}</div>
          </div> -->

        </div>
      </div>
    </div>
    <!-- 接入尾部组件 -->
    <mybottom></mybottom>
  </div>
</template>

<script>
import myheader from '@/components/Header.vue';
import mybottom from '@/components/Bottom.vue';
export default {
  components: { myheader, mybottom },
  data() {
    return {
      id: -1, //修改文章编号
      news: [],
      fileList: [],
      // 文章分类数据
      // sections: [],
      fileInfos: [],
      // 显示附件
      accessoryIs: true,

      size: 'large',
    };
  },
  created() {
    this.id = this.$route.query.id;
    // this.form.id = this.$route.params.id;
    if (this.id == undefined) {
      // this.$router.push({
      //   name: 'newsList',
      // });
    } else {
      this.loadNews();
    }
  },
  methods: {
    download(val) {
      console.log(this.serverUrl + val);

      var x = new XMLHttpRequest();
      x.open('GET', `${this.serverUrl}${val}`, true);
      x.responseType = 'blob';
      x.onload = function (e) {
        var url = window.URL.createObjectURL(x.response);
        var a = document.createElement('a');
        a.href = url;
        a.download = '';
        a.click();
      };
      x.send();
      //   window.open(`${this.serverUrl}${val}`);
    },
    /**读取文章信息 */
    async loadNews() {
      let result = await this.axios.get(`/news/find/${this.id}`);
      this.news = result.data.data;
      let arr = this.news.accessories;
      if (Array.isArray(arr) && arr.length === 0) {
        this.accessoryIs = false;
      }
      //   this.form.accessoryList = this.form.accessories;
    },
  },
};
</script>

<style>
.content-text img {
  max-width: 100%;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.container {
  background-color: #f5f6f6;
  overflow: hidden;
}
.top {
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #f0f2f5;
  max-width: 1200px;
  margin: 0 auto;
  padding: 25px 0;
  background-color: #fff;
}
.new-bottom {
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: #f0f2f5;
  padding: 30px 0;
  background-color: #fff;
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 100px;
}
.content-text {
  margin-top: 15px;
}
.content {
  max-width: 1200px;
  margin: 0 auto;
  background-color: #fff;
  padding: 10px 200px;
}
.ant-card-body {
  padding: 0;
}
img {
  max-width: 100%;
}
</style>
